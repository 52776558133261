<template>
  <div class="common-content detail-container" v-if="detail.need_subscribe === 0">
    <el-page-header @back="goBack" content="知识库"></el-page-header>
    <el-alert v-if="detail.status < 0" type="warning" :closable="false">{{ detail.failed_log.remark }}</el-alert>
    <!-- 文本 -->
    <div v-if="detail.type === 1">
      <h1 class="detail-title">{{ detail.title }}</h1>
      <p class="detail-date">{{ detail.pub_date }}</p>
      <p class="detail-desc" v-html="detail.digest" v-if="detail.file == ''"></p>
      <iframe width="100%" height="600" frameborder="0" scrolling="no" :src="detail.file"
        v-if="detail.file != ''"></iframe>
    </div>
    <!-- 视频 -->
    <div v-else-if="detail.type === 2">
      <h1 class="detail-title">{{ detail.title }}</h1>
      <!--controlslist="nodownload"-->
      <video id="video" controls onpause preload="meta" controlslist="nodownload">
        <source :src="detail.file" type="video/mp4">
      </video>
    </div>
  </div>
  <div class="common-content detail-container" v-else-if="detail.need_subscribe > 0">
    <el-page-header @back="goBack" content="知识库"></el-page-header>
    <el-alert title="此文章为收费文章，若您还不是VIP，成为VIP更优惠哦^-^" type="error" :closable="false"></el-alert>
    <router-link active-class="news-item" :to="{ name: 'Buygoods', params: { id: detail.id } }">
      <el-button type="primary">立即购买</el-button>
    </router-link>
<!--    <router-link active-class="news-item" :to="{ name: 'Vip', params: { id: detail.id } }">-->
<!--      <el-button type="primary">充值VIP会员</el-button>-->
<!--    </router-link>-->
  </div>
</template>
<script>
let loading = null
let wxSdk = null
let lastTime = 0

export default {
  data() {
    return {
      detail: {}
    }
  },
  beforeCreate() {
    loading = this.$loading({
      lock: true,
      text: '数据加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
  },
  computed: {},
  methods: {
    isWechat() {
      const _thisVue = this
      const ua = navigator.userAgent.toLowerCase()
      const isWeixin = ua.indexOf('micromessenger') !== -1
      if (isWeixin) {
        wxSdk = require('weixin-js-sdk')
        this.$store.dispatch('getWechatJssdk').then((res) => {
          wxSdk.config(res)
        })
        wxSdk.ready(function () {
          wxSdk.updateAppMessageShareData({
            title: _thisVue.detail.title, // 分享标题
            desc: _thisVue.detail.digest, // 分享描述
            link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: _thisVue.detail.cover, // 分享图标
            success: function () {
              // 设置成功
            }
          })
          wxSdk.updateTimelineShareData({
            title: _thisVue.detail.title, // 分享标题
            desc: _thisVue.detail.digest, // 分享描述
            // link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: _thisVue.detail.cover, // 分享图标
            success: function () {
              // 设置成功
            }
          })
          wxSdk.onMenuShareAppMessage({
            title: _thisVue.detail.title, // 分享标题
            desc: _thisVue.detail.digest, // 分享描述
            // link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: _thisVue.detail.cover, // 分享图标
            // type: '', // 分享类型,music、video或link，不填默认为link
            // dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {
            }
          })
          wxSdk.onMenuShareTimeline({
            title: _thisVue.detail.title, // 分享标题
            desc: _thisVue.detail.digest, // 分享描述
            // link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: _thisVue.detail.cover, // 分享图标
            success: function () {
              // 设置成功
            }
          })
        })
      }
    },
    // 文库详情
    getArticleDetail(id) {
      this.$store.dispatch('getArticleDetail', id).then(res => {
        this.detail = res
      }).then(() => {
        this.isWechat()
        // if (document.getElementById('video')) {
        //   document.getElementById('video').currentTime = this.detail.play_at
        // }
        loading.close()
      })
    },
    // 商家文章详情
    getStoreArticle(id) {
      this.$store.dispatch('getStoreArticleDetail', id).then(res => {
        this.detail = res
        this.detail.file = res.sign_url_preview
        if (!this.detail.failed_log) {
        }
      }).then(() => {
        this.isWechat()
        // if (document.getElementById('video')) {
        //   document.getElementById('video').currentTime = this.detail.play_at
        // }
        loading.close()
      })
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    if (this.$route.name === 'Librarydetail') {
      // 获取文库详情
      this.getArticleDetail(this.$route.params.id)
    }
    if (this.$route.name === 'StoreDetail') {
      // 商家文章详情
      this.getStoreArticle(this.$route.params.id)
    }
    setInterval(() => {
      if (document.getElementById('video')) {
        let obj = {
          article_id: this.detail.id,
          play_at: document.getElementById('video').currentTime
        }
        this.$store.dispatch('setTime', obj).then(res => {
          // console.log(res)
        })
      }
    }, 60000)
    // 设置视频播放时间 禁止快进
    setTimeout(() => {
      if (document.getElementById('video')) {
        let video = document.getElementById('video')
        video.currentTime = this.detail.play_at
        video.ontimeupdate = function () {
          var currTime = video.currentTime
          if (currTime - lastTime > 2) {
            video.currentTime = lastTime
          } else {
            lastTime = currTime
          }
        }
      }
    }, 1000)
    // console.log(loading)
    loading.close()
  }
}
</script>
<style lang="less">
#video {
  max-width: 1024px;
  max-height: 768px;
}

.moblile #video {
  max-width:100%;
  max-height: auto;
}

.detail-container {
  margin-bottom: 2rem;
  box-shadow: 5px 5px 5px #f2f2f2;
  padding-bottom: 2rem;

  h1 {
    font-weight: normal;
    font-size: 2rem;
    color: @global-black;
  }

  .detail-date {
    font-size: 1.5rem;
  }

  a {
    margin-right: 2rem;

    .el-button {
      margin-top: 3rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
